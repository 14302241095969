/* Estilos para o footer */
footer {
  background-color: rgb(250, 250, 250);
  height: auto;
  min-height: auto;
  margin-top: 0px;
  width: 100%;
}

footer>div {
  padding: 64px 24px;
}

footer>div>div {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  gap: 2rem;
}

footer .footer--container1-excofy-logo {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

footer .footer--container1-text01 {
  margin: 0px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgb(158, 158, 158);
}

footer .footer--container1-text02 {
  margin: 0px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  color: rgb(158, 158, 158);
}

footer .footer--container1-text03 {
  margin: 0px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgb(117, 117, 117);
}

footer .footer--container2-text01 {
  margin: 0px 0px 8px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgb(117, 117, 117);
}

.footer--container1 h1 {
  color: #9e9e9e;
  margin: 0px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 24px;
  line-height: 1.334;
  color: #9e9e9e;
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 0;
  margin-left: 8px;
}

footer a:hover {
  color: var(--dl-color-main-primary);
}

footer .facebook-icon:hover {
  color: rgb(27, 116, 228) !important;
}

footer .instagram-icon:hover {
  color: rgb(203, 123, 18) !important;
}

footer .youtube-icon:hover {
  color: rgb(255, 0, 0) !important;
}