main {
  margin-top: 93px;
}

a.link {
  color: var(--dl-color-main-primary);
}

a.link:hover {
  color: var(--dl-color-main-primary);
}

/* Estilos para o header */
#privacy-policy header {
  background-color: var(--dl-color-main-white);
  position: fixed;

  width: 100%;
  z-index: 1;
  top: 0;
  max-width: 100%;
}

#privacy-policy header .header--container {
  max-width: 100%;
}

.privacy-policy--content {
  margin-left: 275px;
  width: calc(100% - 275px);
}

.privacy-policy--container {
  background-color: var(--dl-color-main-white);
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  padding: 90px 56px 48px;
  padding: 40px;
  width: 100%;
}

.privacy-policy--title {
  font-weight: 500;
  font-size: 32px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-bottom: 8px;
}

.privacy-policy--title-border {
  border: 1px solid #eee;
  width: 100%;
  flex: 1;
}

.privacy-policy--sections-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.privacy-policy--section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-size: cover;
}

.privacy-policy--subtitle {
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
}

.privacy-policy-paragraph {
  color: var(--dl-color-main-body);
  font-size: 18px;
  font-stretch: normal;
  line-height: 28px;
  margin: 16px 0;
}

.privacy-policy-unordered-list {
  margin-left: 24px;
}

.privacy-policy-unordered-list li,
.privacy-policy-ordered-list li {
  font-size: 18px;
  font-stretch: normal;
  line-height: 28px;
}

#privacy-policy b {
  font-weight: 600;
}

#privacy-policy h1#title {
  font-weight: 500;
  font-size: 32px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-bottom: 8px;
}

section h3 {
  font-weight: 500;
  margin: 16px 0;
}

@media screen and (max-width: 800px) {
  .privacy-policy--content {
    margin-left: 0;
    width: 100%;
  }

  .privacy-policy--container {
    padding: 40px 24px;
  }
}

@media screen and (max-width: 375px) {
  .privacy-policy--container {
    padding: 24px 16px;
  }
}