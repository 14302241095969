header {
  display: flex;
  padding: 16px 24px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.header--container {
  gap: 32px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
}

.header--buttons-container {
  gap: 48px;
  display: flex;
  align-items: center;
}

.header--buttons-container .light-button {
  color: var(--dl-color-main-body);
  height: auto;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  line-height: 28px;
  font-stretch: normal;
  transition: color 0.2s;
  padding: 4px;
}

.header--buttons-container .light-button:hover {
  color: var(--dl-color-main-primary);
}

@media (max-width: 600px) {
  .header--container .header--buttons-container {
    display: none !important;
  }
}