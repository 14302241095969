.sidebar::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
  
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Cor do scroll */
  border-radius: 10px;
  /* Bordas arredondadas */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Cor ao passar o mouse */
}

.sidebar::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  /* Cor do track (fundo da barra de rolagem) */
}

.sidebar {
  background-color: var(--dl-color-main-white);
  position: fixed;
  height: 100%;
  overflow-y: auto;
  width: 275px;
  border-right: 1px solid rgb(238, 238, 238);
  overflow-y: auto;
  height: calc(100vh - 93px);
  padding: 32px 8px 48px;
}

.sidebar>div {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sidebar h3 {
  padding: 8px 12px;
  font-weight: 500;
}

.sidebar a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: block;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
  padding: 4px 12px;
  border-radius: 12px;
}

.sidebar a:hover, .sidebar a.selected:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.sidebar a.selected {
  background-color: rgba(0, 0, 0, 0.04);
  color: var(--dl-color-main-body);
  font-weight: 450;
}

@media screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }
}