main {
  margin-top: 93px;
}

a.link {
  color: var(--dl-color-main-primary);
}

a.link:hover {
  color: var(--dl-color-main-primary);
}

/* Estilos para o header */
#terms-of-use header {
  background-color: var(--dl-color-main-white);
  position: fixed;

  width: 100%;
  z-index: 1;
  top: 0;
  max-width: 100%;
}

#terms-of-use header .header--container {
  max-width: 100%;
}

/* Estilos para o conteúdo */
.terms-of-use--content {
  margin-left: 275px;
  width: calc(100% - 275px);
}

.terms-of-use--container {
  background-color: var(--dl-color-main-white);
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px;
  margin: 0 auto;
  width: 100%;
}

h1#title {
  font-weight: 500;
  font-size: 32px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-bottom: 8px;
}

.terms-of-use--subtitle {
  font-weight: 500;
  font-size: 24px;
  font-style: normal;
}

.terms-of-use--sections-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.terms-of-use--section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-size: cover;
}

.terms-of-use-paragraph {
  color: var(--dl-color-main-body);
  font-size: 18px;

  line-height: 28px;
  margin: 16px 0;
}

.terms-of-use--tag {
  font-size: 16px;
  font-stretch: normal;
  color: #757575;
  text-align: right;
  width: 100%;
}

.terms-of-use-unordered-list {
  margin-left: 24px;
}

.terms-of-use-unordered-list li {
  font-size: 18px;
  font-stretch: normal;
  line-height: 28px;
}

polygon {
  fill: black;
}
@media (prefers-color-scheme: dark) {
  polygon { fill: white; }
}

@media screen and (max-width: 800px) {
  .terms-of-use--content {
    margin-left: 0;
    width: 100%;
  }

  .terms-of-use--container {
    padding: 40px 24px;
  }
}

@media screen and (max-width: 375px) {
  .terms-of-use--container {
    padding: 24px 16px;
  }
}